import { connect } from 'react-redux';
import { TWAvatarDropdown } from '.';

var mapStateToProps = function mapStateToProps(state) {
  var _state$auth, _state$auth$users, _state$auth$users$cur, _state$auth$users$cur2, _state$provider$provi, _state$auth$users$cur3;

  return {
    initials: (((_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : (_state$auth$users = _state$auth.users) === null || _state$auth$users === void 0 ? void 0 : (_state$auth$users$cur = _state$auth$users.current) === null || _state$auth$users$cur === void 0 ? void 0 : (_state$auth$users$cur2 = _state$auth$users$cur.firstName) === null || _state$auth$users$cur2 === void 0 ? void 0 : _state$auth$users$cur2.substr(0, 1)) || '').toUpperCase(),
    avatar: (_state$provider$provi = state.provider.providerServices[state.provider.providerSelectedServiceId]) === null || _state$provider$provi === void 0 ? void 0 : _state$provider$provi.avatarUrl,
    items: [{
      text: 'New Service',
      // TODO: Link to new service flow
      // href: '#',
      icon: 'plus',
      hidden: true
    }, {
      text: 'Account Settings',
      href: '#',
      hidden: true
    }, {
      text: 'Switch to Dashboard',
      href: (_state$auth$users$cur3 = state.auth.users['current']) !== null && _state$auth$users$cur3 !== void 0 && _state$auth$users$cur3.provider ? '/dashboard' : '/subscriber-dashboard',
      icon: 'repeat-alt'
    }, {
      text: 'Logout',
      href: '/logout'
    }]
  };
};

var mapDispatchToProps = function mapDispatchToProps() {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TWAvatarDropdown);